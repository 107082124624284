/* eslint-disable */
  import { $themeConfig } from '@themeConfig'

  // const mlfname = $themeConfig.app.appName
  // // const mlffullname = $themeConfig.app.appName
  // // const mlfshortname = $themeConfig.app.appName
  // const mlflogourl = $themeConfig.app.appLogoImage
  // const mlflogo = $themeConfig.app.appLogoImage
  // const mlffeurl = $themeConfig.app.appFEURL
  // const mlfavatar = $themeConfig.mlfUserData.avatar

  // export { mlfname, mlflogourl, mlflogo, mlffeurl, mlfavatar }

  // function init(){
  //   const { appName, appLogoImage, appFEURL } = $themeConfig.app
  // }

  export function mlfname() {
    return $themeConfig.app.appName
  }

  export function mlflogourl() {
    return $themeConfig.app.appLogoImage
  }

  export function mlflogo() {
    return `<img :src="${$themeConfig.app.appLogoImage}" alt="logo" class="">`
    // return `<img :src="$themeConfig.app.appLogoImage" alt="logo" class="">`
  }

  export function mlffeurl() {
    return $themeConfig.app.appFEURL
  }

  export function mlfavatar() {
    return $themeConfig.mlfUserData.avatar
  }
/* eslint-disable */