export default [
  {
    path: '/error-404',
    name: 'error-404',
    // component: () => import('@/views/error/Error404.vue'),
    component: () => import('@mlfcore/pages/misc/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    // component: () => import('@/views/pages/authentication/Login.vue'),
    // component: () => import('@views/mlf/pages/auth/main-login.vue'),
    component: () => import('@mlfcore/pages/auth/main-login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
  },
  {
    path: '/pages/profile',
    name: 'pages-profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
  },

  {
    path: '/pages/faq',
    name: 'pages-faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/knowledge-base',
    name: 'pages-knowledge-base',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBase.vue'),
    meta: {
      pageTitle: 'Knowledge Base',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Knowledge Base',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/knowledge-base/:category',
    name: 'pages-knowledge-base-category',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue'),
    meta: {
      pageTitle: 'Category',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base',
        },
        {
          text: 'Category',
          active: true,
        },
      ],
      navActiveLink: 'pages-knowledge-base',
    },
  },
  {
    path: '/pages/knowledge-base/:category/:slug',
    name: 'pages-knowledge-base-question',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategoryQuestion.vue'),
    meta: {
      pageTitle: 'Question',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base',
        },
        {
          text: 'Category',
          to: '/pages/Knowledge-base/category',
        },
        {
          text: 'Question',
          active: true,
        },
      ],
      navActiveLink: 'pages-knowledge-base',
    },
  },
  {
    path: '/pages/pricing',
    name: 'pages-pricing',
    component: () => import('@/views/pages/pricing/Pricing.vue'),
  },
  {
    path: '/pages/blog/list',
    name: 'pages-blog-list',
    component: () => import('@/views/pages/blog/BlogList.vue'),
    meta: {
      pageTitle: 'Blog List',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Blog',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/blog/:id',
    name: 'pages-blog-detail',
    component: () => import('@/views/pages/blog/BlogDetail.vue'),
    meta: {
      pageTitle: 'Blog Detail',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Blog',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/blog/edit/:id',
    name: 'pages-blog-edit',
    component: () => import('@/views/pages/blog/BlogEdit.vue'),
    meta: {
      pageTitle: 'Blog Edit',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Blog',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // MLF Pages
  // {
  //   path: '/dataverse/',
  //   name: 'mlf-dataverse-',
  //   component: () => import('@mlfcore/pages/dataverse/.vue'),
  // },
  {
    path: '/dataverse/alert-types',
    name: 'mlf-dataverse-alert-types',
    component: () => import('@mlfcore/pages/dataverse/alert-types.vue'),
  },
  {
    path: '/dataverse/alert-recipients',
    name: 'mlf-dataverse-alert-recipients',
    component: () => import('@mlfcore/pages/dataverse/alert-recipients.vue'),
  },
  {
    path: '/dataverse/default-tags',
    name: 'mlf-dataverse-default-tags',
    component: () => import('@mlfcore/pages/dataverse/default-tags.vue'),
  },
  {
    path: '/dataverse/media-types',
    name: 'mlf-dataverse-media-types',
    component: () => import('@mlfcore/pages/dataverse/media-types.vue'),
  },
  {
    path: '/dataverse/notification-types',
    name: 'mlf-dataverse-notification-types',
    component: () => import('@mlfcore/pages/dataverse/notification-types.vue'),
  },
  {
    path: '/dataverse/profile-links',
    name: 'mlf-dataverse-profile-links',
    component: () => import('@mlfcore/pages/dataverse/profile-links.vue'),
  },
  {
    path: '/dataverse/purchase-types',
    name: 'mlf-dataverse-purchase-types',
    component: () => import('@mlfcore/pages/dataverse/purchase-types.vue'),
  },
  {
    path: '/dataverse/user-role-types',
    name: 'mlf-dataverse-user-role-types',
    component: () => import('@mlfcore/pages/dataverse/user-role-types.vue'),
  },
  {
    path: '/tools/support-requests',
    name: 'mlf-tools-support-requests',
    component: () => import('@mlfcore/pages/tools/support-requests.vue'),
  },
  {
    path: '/tools/tasks',
    name: 'mlf-tools-tasks',
    component: () => import('@mlfcore/pages/tools/tasks.vue'),
  },
  {
    path: '/dashboards/analytics',
    name: 'mlf-dashboard-analytics',
    component: () => import('@mlfcore/pages/dashboards/analytics.vue'),
  },
  {
    path: '/dashboards/campaigns',
    name: 'mlf-dashboard-campaigns',
    component: () => import('@mlfcore/pages/dashboards/campaigns.vue'),
  },
  {
    path: '/dashboards/creators-activity',
    name: 'mlf-dashboard-creators-activity',
    component: () => import('@mlfcore/pages/dashboards/creators-activity.vue'),
  },
  {
    path: '/dashboards/default',
    name: 'mlf-dashboard-default',
    component: () => import('@mlfcore/pages/dashboards/default.vue'),
  },
  {
    path: '/dashboards/ecommerce',
    name: 'mlf-dashboard-ecommerce',
    component: () => import('@mlfcore/pages/dashboards/ecommerce.vue'),
  },
  {
    path: '/dashboards/system-health',
    name: 'mlf-dashboard-system-health',
    component: () => import('@mlfcore/pages/dashboards/system-health.vue'),
  },
  {
    path: '/dashboards/viewers-activity',
    name: 'mlf-dashboard-viewers-activity',
    component: () => import('@mlfcore/pages/dashboards/viewers-activity.vue'),
  },
  {
    path: '/system/access-control',
    name: 'mlf-system-access-control',
    component: () => import('@mlfcore/pages/system/access-control.vue'),
  },
  {
    path: '/system/env-settings',
    name: 'mlf-system-env-settings',
    component: () => import('@mlfcore/pages/system/env-settings.vue'),
  },
  {
    path: '/system/cron-jobs',
    name: 'mlf-system-cron-jobs',
    component: () => import('@mlfcore/pages/system/cron-jobs.vue'),
  },
  {
    path: '/system/logging/backend',
    name: 'mlf-system-logging-backend',
    component: () => import('@mlfcore/pages/system/logging/backend.vue'),
  },
  {
    path: '/system/logging/frontend',
    name: 'mlf-system-logging-frontend',
    component: () => import('@mlfcore/pages/system/logging/frontend.vue'),
  },
  {
    path: '/system/logging/commits',
    name: 'mlf-system-logging-commits',
    component: () => import('@mlfcore/pages/system/logging/commits.vue'),
  },
  {
    path: '/system/logging/mailing',
    name: 'mlf-system-logging-mailing',
    component: () => import('@mlfcore/pages/system/logging/mailing.vue'),
  },
  {
    path: '/system/developer/services',
    name: 'mlf-system-developer-services',
    component: () => import('@mlfcore/pages/system/developer/services.vue'),
  },
  {
    path: '/system/developer/code',
    name: 'mlf-system-developer-code',
    component: () => import('@mlfcore/pages/system/developer/code.vue'),
  },
  {
    path: '/system/developer/databases',
    name: 'mlf-system-developer-databases',
    component: () => import('@mlfcore/pages/system/developer/databases.vue'),
  },
  {
    path: '/system/developer/gitlab',
    name: 'mlf-system-developer-gitlab',
    component: () => import('@mlfcore/pages/system/developer/gitlab.vue'),
  },
  {
    path: '/system/developer/ssh',
    name: 'mlf-system-developer-ssh',
    component: () => import('@mlfcore/pages/system/developer/ssh.vue'),
  },
  {
    path: '/system/developer/storage',
    name: 'mlf-system-developer-storage',
    component: () => import('@mlfcore/pages/system/developer/storage.vue'),
  },
  {
    path: '/system/developer/servers',
    name: 'mlf-system-developer-servers',
    component: () => import('@mlfcore/pages/system/developer/servers.vue'),
  },
  {
    path: '/users/view/all',
    name: 'mlf-users-all',
    component: () => import('@mlfcore/pages/users/view/all.vue'),
  },
  {
    path: '/users/view/creators',
    name: 'mlf-users-creators',
    component: () => import('@mlfcore/pages/users/view/creators.vue'),
  },
  {
    path: '/users/view/top-earners',
    name: 'mlf-users-top-earners',
    component: () => import('@mlfcore/pages/users/view/top-earners.vue'),
  },
  {
    path: '/users/view/viewers',
    name: 'mlf-users-viewers',
    component: () => import('@mlfcore/pages/users/view/viewers.vue'),
  },
  {
    path: '/users/view/deleted',
    name: 'mlf-users-deleted',
    component: () => import('@mlfcore/pages/users/view/deleted.vue'),
  },
  {
    path: '/users/payouts/approved',
    name: 'mlf-users-payouts-approved',
    component: () => import('@mlfcore/pages/users/payouts/approved.vue'),
  },
  {
    path: '/users/payouts/rejected',
    name: 'mlf-users-payouts-rejected',
    component: () => import('@mlfcore/pages/users/payouts/rejected.vue'),
  },
  {
    path: '/users/payouts/pending',
    name: 'mlf-users-payouts-pending',
    component: () => import('@mlfcore/pages/users/payouts/pending.vue'),
  },
  {
    path: '/users/applications/approved',
    name: 'mlf-users-applications-approved',
    component: () => import('@mlfcore/pages/users/applications/approved.vue'),
  },
  {
    path: '/users/applications/rejected',
    name: 'mlf-users-applications-rejected',
    component: () => import('@mlfcore/pages/users/applications/rejected.vue'),
  },
  {
    path: '/users/applications/pending',
    name: 'mlf-users-applications-pending',
    component: () => import('@mlfcore/pages/users/applications/pending.vue'),
  },
]
