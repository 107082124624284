import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  /* Dev Testing */
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}

  /* Prod Ready */
  // validateStatus: () => true,
  // baseURL: 'https://api.myluckyfans.com/api/admin',
  baseURL: 'https://api.myluckyfans.com/api/admin',
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  // xsrfCookieName: 'XSRF-TOKEN',
  // xsrfHeaderName: 'X-XSRF-TOKEN',
})

Vue.prototype.$http = axiosIns

export default axiosIns
